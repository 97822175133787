import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Hero() {
  const notify = () =>
    toast.success("Thank you, We'll get back to you soon!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const form = useRef();
  // "service_qsptl6n", "template_ir6450n", form.current, "9AfN8eK5YEzdBYvqo";
  const sendEmail = (e) => {
    e.preventDefault();

    // "service_o23o6jb", "template_uremfya", form.current, "UvZT-t9hwuk760JPm";
    // "service_qsptl6n", "template_ir6450n", form.current, "9AfN8eK5YEzdBYvqo";

    emailjs
      .sendForm(
        "service_rfxdbgj",
        "template_ufs69fo",
        form.current,
        "WE89FSLuRHr8xJk_e"
      )
      .then(
        (result) => {
          console.log(result.text);
          if (result.status == 200) {
            notify();
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="relative min-h-screen  overflow-hidden">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="object-cover w-full h-full absolute top-0 left-0"
        >
          <source
            src="https://themepainters.s3.ap-south-1.amazonaws.com/Design+banner_1.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <div className="">
          <div className="absolute inset-0 flex justify-end ">
            <div className="min-h-screen w-full lg:w-1/2 flex justify-center bg-[#00000081]  items-center ">
              {/* ... rest of your existing code ... */}
              <div className=" h-[90%] lg:max-h-[550px] ">
                <div className="h-full container flex flex-col justify-between">
                  {/* text */}
                  <div className=""></div>
                  <div className=" flex flex-col flex-1 justify-evenly">
                    <div className=" text-white text-center  md:text-3xl  text-[1.4rem] xl:text-4xl font-bold">
                      <span
                        style={{ textShadow: "1px 1px 1px #000000" }}
                        className=""
                      >
                        Customized Christmas Paintings
                      </span>
                    </div>
                    <div className="py-4">
                      <div className="flex px-10  flex-col sm:flex-row sm:text-left text-center  gap-4 text-white justify-around">
                        <ul
                          style={{ textShadow: "1px 1px 1px #000000" }}
                          className="sm:list-disc list-none"
                        >
                          <li className=" text-base">100+ designs</li>
                          <li className="mt-4 text-base">
                            Completion in 4 hours
                          </li>
                        </ul>
                        <ul
                          style={{ textShadow: "1px 1px 1px #000000" }}
                          className="sm:list-disc list-none"
                        >
                          <li className=" text-base">INR 150 per sqft*</li>
                          <li className="mt-4 text-base">Experienced Artist</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* form */}
                  <div className="container px-8 md:px-24 max-w-[600px]   flex justify-center ">
                    <div className="h-fit w-fit bg-white">
                      <div className="bg-black w-full p-1 text-center text-white   text-xl tracking-wider">
                        Let’s Connect
                      </div>
                      <div className="p-4">
                        <form
                          ref={form}
                          onSubmit={sendEmail}
                          className="w-full"
                        >
                          <div className="flex flex-col ">
                            <input
                              type="text"
                              name="name"
                              placeholder="Name"
                              className=" w-full p-1 px-3 sm:mb-4  mb-2 mt-2 hover:border-red-500 placeholder-zinc-600   bg-[#D5D5D5] border text-zinc-500  rounded-lg"
                            />
                            <input
                              type="email"
                              name="email"
                              placeholder="E-mail ID"
                              className="  w-full p-1 px-3 sm:mb-4  mb-2 hover:border-red-500 placeholder-zinc-600   bg-[#D5D5D5] border text-zinc-500  rounded-lg"
                            />
                            <input
                              type="text"
                              name="number"
                              placeholder="Mobile no."
                              className="w-full appearance-none p-1 px-3 mb-4 sm:mb-4 hover:border-red-500 placeholder-zinc-600   bg-[#D5D5D5] border text-zinc-500  rounded-lg"
                            />

                            <button className="bg-[#7719B0] hover:text-yellow-950 px-4 py-1 sm:mb-4  text-white  rounded-lg w-28">
                              Get Price
                            </button>
                            <p className="italic text-base tracking-wider mt-4 md:mt-0 px-4 text-zinc-700 ">
                              If I could say it in words, there would be no
                              reason to paint. — Edward Hopper
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
      {/* ------------------- */}
    </>
  );
}

export default Hero;
