import React from "react";

function PrivacyPolicy() {
  return (
    <div className="bg-white text-black p-8 w-screen min-h-screen">
      <div className="text-4xl mb-8 font-bold text-center">Privacy Policy</div>
      <div className="text-justify text-2xl  indent-6">
        We collect information from you when you submit an enquiry or contact us
        through our website. If you provide your information, the information
        will strictly be used to send you information, respond to your
        enquiries, and/or other requests or questions only with correspondence
        to you. Disclosure of any information to outside parties: Theme Painters
        does not share your information to any outside party. Online Privacy
        Policy: This online privacy policy applies only to information we
        receive by you directly. Your Consent By using our website, you consent
        to our website privacy policy. Changes to Privacy Policy: If and when,
        we decide to change our privacy policy, we will post those changes on
        this page of privacy policies. Compliance: Our Privacy Policy and our
        approach to privacy adhering to the National Privacy Principles of the
        Government of India contained within the new Privacy Act. Contacting Us
        : If there are any questions regarding the above privacy policy, you may
        contact us using the information given below: G49,50, Sector - 3, Noida
        201301 (INDIA), Email: info.themepainter@gmail.com
      </div>
    </div>
  );
}

export default PrivacyPolicy;
