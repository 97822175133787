import React from "react";
import { FaPhoneVolume, FaWhatsapp, FaBrush } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import PrivacyPolicy from "./PrivacyPolicy";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-gray-800 text-white p-8 text-center ">
      <div className="container mx-auto flex flex-wrap justify-between flex-col items-center md:flex-row md:items-center">
        {/* Company Logo */}
        <div className="flex flex-col justify-center items-center w-full py-8  sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4  ">
          <h1 className="font-extrabold flex items-center w-fit rounded-lg  md:p-2 p-1 px-2 border-2 border-white  justify-center ">
            <FaBrush className="text-6xl  mr-4 text-center" />
            <div className="text-3xl">
              <h1>Theme </h1>
              <div className="h-1 bg-white"></div>
              <h1>Painters</h1>
            </div>
          </h1>
        </div>
        <div className="w-full text-lg font-bold underline sm:w-1/2 md:w-1/4 py-8 lg:w-1/4 xl:w-1/4 mb-4">
          <Link to={"/privacy-policy"}>Privacy Policy</Link>
        </div>
        {/* Other Services */}
        <div className="w-full sm:w-1/2 md:w-1/4 py-8 lg:w-1/4 xl:w-1/4 mb-4">
          <h3 className="text-lg font-bold mb-2">Wall Art Services</h3>
          <ul>
            <li>Unique Restaurant Theme</li>
            <li>Canvas Painting</li>
            <li>Wall Mural Design</li>
            <li>Sculptures & Installation Design</li>
            <li>Office Graffiti Design</li>
            <li>Commercial Wall Art</li>
          </ul>
        </div>

        {/* Contacts */}
        <div className="w-full sm:w-1/2 py-8 md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4 text-center md:text-right select-text">
          <h3 className="text-lg font-bold mb-2">Corporate Address</h3>
          <p>G-49,50, 3rd Floor, office no. 2,</p>
          <p>Sector - 3, Noida, UP - 201301</p>
          <div>
            <a href="info.themepainters@gmail.com">
              <FaEnvelope className="inline" /> info.themepainters@gmail.com
            </a>
          </div>
          <div>
            <a href="tel:+919911302024">
              <FaPhoneVolume className="inline" /> +919911302024
            </a>
          </div>

          <div>
            <a
              className="whitespace-nowrap"
              href="https://wa.me/919911302024?text=Hello"
            >
              <FaWhatsapp className="inline" /> +919911302024
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
