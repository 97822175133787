import React, { useState } from "react";

import Slider from "react-slick";
import { FaPhoneVolume, FaWhatsapp } from "react-icons/fa6";
import { FaEnvelope, FaArrowCircleRight } from "react-icons/fa";
import Modal from "react-modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Hero from "../Components/Hero";

const customStyles = {
  overlay: {
    backgroundColor: "transparent",
  },
  content: {
    width: "50%",
    maxHeight: "100%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const data = [
  {
    name: `Army Hospital`,
    img: `https://themepainters.s3.ap-south-1.amazonaws.com/Army_Hospital.jpg`,
    review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  },
  {
    name: `Cafe`,
    img: `https://themepainters.s3.ap-south-1.amazonaws.com/Cafe_Ki+Hangla_.jpg`,
    review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  },
  // {
  //   name: `Design Banner`,
  //   img: `https://themepainters.s3.ap-south-1.amazonaws.com/Design+banner_1.mp4`,
  //   review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  // },
  {
    name: `Dog Park`,
    img: `https://themepainters.s3.ap-south-1.amazonaws.com/Dog+Park.jpeg`,
    review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  },

  // {
  //   name: `Wall`,
  //   img: `https://themepainters.s3.ap-south-1.amazonaws.com/home-img.png`,
  //   review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  // },
  {
    name: `Army Hospital`,
    img: `https://themepainters.s3.ap-south-1.amazonaws.com/Army+Hospital.jpg`,
    review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  },
  {
    name: `Out Door`,
    img: `https://themepainters.s3.ap-south-1.amazonaws.com/Outdoor.jpg`,
    review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  },
  {
    name: `School`,
    img: `https://themepainters.s3.ap-south-1.amazonaws.com/School.jpg`,
    review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  },
  {
    name: `Clinic`,
    img: `https://themepainters.s3.ap-south-1.amazonaws.com/Vet+Clinic.png`,
    review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  },
  {
    name: `Yoga studio`,
    img: `https://themepainters.s3.ap-south-1.amazonaws.com/yoga+studio.jpg`,
    review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  },
];

function Home() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {" "}
      <div className="font-myfont select-none">
        <Hero />
        <div className=" m-auto bg-black p-8  text-white">
          <div className="text-white text-center py-8  md:text-3xl  text-ba whitespace-nowrap xl:text-4xl font-bold tracking-wider">
            Customized Christmas Paintings
          </div>
          <div className="flex flex-wrap gap-16  justify-evenly  text-center">
            <div className="md:p-8 p-4">
              <div>400+</div>
              <div>Happy Customers</div>
            </div>
            <div className="md:p-8 p-4">
              <div>
                <div>4.7</div>
                <div>Global Ratings</div>
              </div>
            </div>
            <div className="md:p-8 p-4">
              <div>
                <div>57000+ Sq ft.</div>
                <div> Art Delivered</div>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <Slider {...settings}>
              {data.map((d) => (
                <div
                  key={d.name}
                  className="bg-white  text-black rounded-lg overflow-hidden"
                >
                  <div className="h-56  flex justify-center items-center ">
                    <img src={d.img} alt="" className="h-full w-full" />
                  </div>

                  <div className="flex flex-col items-center justify-center p-4">
                    <p className="text-xl  font-semibold">{d.name}</p>
                    {/* <p className="text-justify">{d.review}</p> */}
                    {/* <button className="bg-indigo-500 text-white text-lg px-6 py-1 rounded-xl">
                    Read More
                  </button> */}
                    <button
                      onClick={() => {
                        openModal();
                        setImgUrl(d.img);
                      }}
                      className="hidden md:block"
                    >
                      <FaArrowCircleRight className="m-1  cursor-pointer" />
                    </button>
                    <Modal
                      isOpen={modalIsOpen}
                      // onAfterOpen={afterOpenModal}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <button
                        onClick={closeModal}
                        className="bg-red-500 rounded-lg text-white px-4 mb-2 w-32 mx-auto text-center"
                      >
                        X
                      </button>

                      <img
                        src={imgUrl}
                        alt=""
                        className="h-full w-full mx-auto"
                      />
                    </Modal>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="bg-black flex flex-col items-center md:p-8 p-1 pt-0">
          <p className="bg-[#7719B0] hover:text-yellow-950 cursor-pointer md:w-3/5 w-full text-center  p-1  whitespace-nowrap md:text-2xl text-[1.2rem] md:tracking-wider md:rounded-full italic text-white">
            Let’s discuss what we can do for you!
          </p>
          <div className="h-fit flex justify-center  items-center md:py-8 py-4 space-x-8 md:space-x-16  m-auto text-white">
            <button className="bg-green-500 py-1 px-2 rounded-lg">
              <a href="tel:+919911302024">
                <FaPhoneVolume className="text-2xl" />
              </a>
            </button>
            <button className="bg-green-500 py-1 px-2 rounded-lg">
              <a className="mt-4" href="https://wa.me/919911302024?text=Hello">
                <FaWhatsapp className="text-2xl" />
              </a>
            </button>
            <button className="bg-green-500 py-1 px-2 rounded-lg">
              <a href="info.themepainters@gmail.com">
                <FaEnvelope className="text-2xl" />
              </a>
            </button>
            {/*
             */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
